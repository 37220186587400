import "core-js/modules/es6.object.freeze.js";
import "core-js/modules/es6.function.name.js";
import { parseMetricForBackend } from '~/functions/metrics';
import { MetricReportingPeriodType, useGetAccountQuery, useUpsertMetricMutation } from '~/types/types';
var temp = Object.freeze({
  name: 'Scope 1 GHG emissions',
  description: 'Our emissions over all our business activities.',
  unitName: 'm CO2e',
  defaultGoalType: 'none',
  defaultGoalTarget: null,
  defaultReportingPeriodType: MetricReportingPeriodType.Annual
});
export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false
    },
    metric: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    onUpdated(function () {
      metricFormValue.value = props.metric;
    });
    var metricFormValue = ref(props.metric);
    var _useGetAccountQuery = useGetAccountQuery(),
      result = _useGetAccountQuery.result;
    var account = computed(function () {
      var _result$value;
      return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getAccount) || {
        id: ''
      };
    });
    var form = ref(null);
    var _useUpsertMetricMutat = useUpsertMetricMutation(),
      mutate = _useUpsertMetricMutat.mutate;
    var save = function save() {
      var _form$value, _form$value$validate;
      if ((_form$value = form.value) !== null && _form$value !== void 0 && (_form$value$validate = _form$value.validate) !== null && _form$value$validate !== void 0 && _form$value$validate.call(_form$value)) {
        var input = parseMetricForBackend(metricFormValue.value);
        input.createdBy = account.value.id;
        mutate({
          input: input
        }).then(function (value) {
          var _value$data;
          emit('input', false);
          emit('created', value === null || value === void 0 ? void 0 : (_value$data = value.data) === null || _value$data === void 0 ? void 0 : _value$data.upsertMetric);
        });
      }
    };
    var disabled = computed(function () {
      var _metricFormValue$valu;
      var isEdit = !!((_metricFormValue$valu = metricFormValue.value) !== null && _metricFormValue$valu !== void 0 && _metricFormValue$valu.id);
      if (isEdit) {
        var _metricFormValue$valu2;
        return ((_metricFormValue$valu2 = metricFormValue.value) === null || _metricFormValue$valu2 === void 0 ? void 0 : _metricFormValue$valu2.createdBy) !== account.value.id;
      } else {
        return !metricFormValue.value.name;
      }
    });
    return {
      disabled: disabled,
      form: form,
      save: save,
      account: account,
      metricFormValue: metricFormValue
    };
  }
});